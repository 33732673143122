.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .modal-content {
    background-color: #e6f4ff; /* Light blue background for the content */
    padding: 0; /* Removed padding here to handle in child elements */
    border-radius: 8px;
    position: relative;
    z-index: 1001;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #b0c4de;
    transform: scale(0.9);
    transition: transform 0.3s ease, background-color 0.2s ease;
  }
  
  .modal-content.active {
    transform: scale(1);
  }
  
  .modal-header {
    background-color: #1e90ff; /* Vivid blue for the header */
    padding: 10px 20px; /* Padding around header content */
    color: white; /* White text for contrast */
    border-top-left-radius: 8px; /* Matching the border radius of modal */
    border-top-right-radius: 8px;
  }
  
  .modal-body {
    padding: 20px; /* Padding for body content */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: white; /* Ensuring visibility on blue background */
    transition: color 0.2s;
  }
  
  .close-button:hover {
    color: #4682b4; /* Deeper blue on hover */
  }
  
  .modal-overlay.active {
    opacity: 1;
  }
  